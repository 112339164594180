
import { Link} from 'react-router-dom';
import Clouds from '../components/clouds';


export default function Footer() {

    return (
  <footer id="footer" className="container-fluid py-3 ">

     <Clouds center={true}/>
  
    <div className=" w-100 text-center">
    <Link to="/" className="nav-link rounded-5">
    <small className="text-muted">© 2021 {new Date().getFullYear()},  Built with &#x2661; by </small> 
    <p className="h6 text-center">Khalil <i className="bi bi-person-workspace fs-3 text-success"></i> Tuban</p>
    </Link>
    </div>

  </footer>
    );
}
