import {useEffect} from 'react'
import { useLocation , useNavigate} from 'react-router-dom';

import { useAuth } from "react-oidc-context";
import { Loading, useQuery } from '../components/helpers';
import {logout} from '../utils/AuthService';


export function LoginOut() {

  const navigate = useNavigate();
  const location = useLocation();
  const searchQuery = useQuery(location as any);
  const returnUrl = searchQuery.get('returnUrl');
  const auth = useAuth()

useEffect(()=>{
  if (auth.isAuthenticated)
     navigate( returnUrl || "/")
  if(auth.error)
    throw auth.error
},[auth.error, auth.isAuthenticated, navigate, returnUrl])

    if(auth.activeNavigator?.indexOf("signinSilent signoutRedirect") === -1){
            return <div className="spinner-border spinner-border-sm" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
    }

    if (auth.isLoading) {
        return <Loading/>;
    }

    return <button className={auth.isAuthenticated ? 'btn btn-sm btn-warning' : 'btn btn-sm btn-primary'} onClick={async(ev) => {
      ev.preventDefault();
      ev.stopPropagation();
      if (auth.isAuthenticated){
      await  logout(auth); 
      navigate(returnUrl || "/" )
      }else{
       await auth.signinRedirect((returnUrl ? {state:returnUrl} : {})); 
      }
      
  }}> {auth.isAuthenticated ?"log out" :"Log in"} <i className={auth.isAuthenticated ? "bi bi-box-arrow-right text-warnimg" :"bi bi-box-arrow-in-left text-dark"}></i></button>;
}
