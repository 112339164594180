import {useLoaderData} from 'react-router-dom';
import { IModelForm} from '../../types/utils';

import { useFarmBuilder } from '../../utils/forms';
import { Loading } from '../../components';
import { useModelLayoutContext } from './default_model_layout';

export default function CreateModel() {

  const modelForm = useLoaderData() as IModelForm; 
  const {modelViewData} = useModelLayoutContext()!
  const { displayName, routeName } = modelViewData.viewData;

  let Extra = ()=>{
    return<div className=" mx-auto text-center mb-3">
    <p className='lead'> Use this form to Create {displayName}</p>
  </div>
  }
  const {AppForm,farmState, setFarmState}= useFarmBuilder({modelForm,sendUrl:routeName+'/create',send:true, Extra:<Extra/>});

  return modelViewData ?  (

    <div className=" container-fluid p-0 justify-content-md-center fadein">
      <div className='row'>
        <div className="col-md-10 col-sm-12 mx-auto ">
            <h4 className='text-center'>{'Create ' + displayName}</h4>
            <hr className='bg-warning' />
               <AppForm/>
        </div>
      </div>
    </div>
  ) : <Loading/>;
}
