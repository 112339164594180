
import { isIn } from '../components/helpers';
import { IModelViewData, IViewData } from '../types/utils'
export class ModelViewtData implements IModelViewData {

    constructor(_viewData: IViewData) {

        if (!_viewData) {
            throw new Error('viewData is required parameter')
        }

        this.viewData = _viewData;
    }

    viewData: IViewData

    inUserAuth(action: string): boolean {
        return isIn(this.viewData.userAuth, action);
    }
    inAdminAuth(action: string): boolean {
        return isIn(this.viewData.adminAuth, action);
    }
    inPlugins(name: string): boolean { return isIn(this.viewData.plugins, name); }

}