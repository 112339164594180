import {useMemo } from "react";
import history from '../utils/history';

import {RouteObject, redirect, createBrowserRouter} from "react-router-dom";
import { Layout, RenderList, RenderModel } from "../components";
import { ErrorPage, Home, Portfolio, Pricing, CheckOut, Login, Signup, UnAuthorized, ForgotPassword, RequireAdmin, Thanks, Test, NotFound, DefaultModelLayout, CreateModel, EditModel, DeleteModel, RootBoundary } from "../pages";
import { Products, ShoppingCart } from "../shopping";
import {IViewData } from "../types/utils";
import { useSiteContext } from "./context";
import { isIn} from "../components/helpers";
import { LoginOut } from "../pages/Login";
import {OAuthCallback, ProtectedRoute} from './AuthService'



export default function useAppRoues(): any{
const cnx = useSiteContext()!


return useMemo(() => {

  const defaultAction:any =  async (viewData: IViewData) => {

    return async function (props: { params: any, request: Request }) {
    
      const { params, request } = props;
      let formData: any = await request.formData();
      const fValues = Object.fromEntries(formData);
      //await updateContact(params.contactId, updates);
      //return redirect(`/contacts/${params.contactId}`);
    
      switch (request.method) {
        case "POST": {
    
          let result = await cnx.ajax.post(viewData.baseRoutePath + '/create', fValues)
          return redirect(viewData.baseRoutePath + '/' + result?.data._id);
        }
        case "PUT": {
    
          let result = await cnx.ajax.put(viewData.baseRoutePath + fValues._id + '/update', fValues)

          return redirect(viewData.baseRoutePath + '/' + result?.data._id);
        }
        case "PATCH": {
          let result = await cnx.ajax.patch(viewData.baseRoutePath + fValues._id + '/patch', fValues)

          return redirect(viewData.baseRoutePath + '/' + result?.data._id);
        }
        case "DELETE": {
    
          let result = await cnx.ajax.deleteReq(viewData.baseRoutePath + fValues._id + '/delete');
          return result?.data._id;
        }
        default: {
          throw new Response("", { status: 405 });
        }
      }
    }
    }
    
    const authLoader = (action: string, viewData: IViewData,getform?: boolean) => async (props: { params: any, request: Request }) => {
     
      const { routeName, name } = viewData;
    
    let modelform = getform ? (await cnx.ajax.get(routeName + '/client/form'))?.data : name;
    let _auth = isIn(viewData.userAuth,action);
    let _admin = isIn(viewData.adminAuth,action);
    
    let _url = new URL(props.request.url);
    let url = _url.pathname+_url.search;
    // push to history
    history.push(url);
    
    if (_admin || _auth) {
    
      if (!cnx.auth.isAuthenticated) {
        return cnx.auth.signinRedirect()
      }else{
        if ( _admin && !cnx.isAdmin) {
        return  redirect('/requireadmin')
      }
    
      }
    }
    
    return modelform;
    
    }
    
  //============================================================================================

let _routes: RouteObject = {
  path: "/",
  element: <Layout />,
  errorElement: <RootBoundary />,
  children: [
    {
      index: true,
      element:  <Home/>,
    },
    {
      path: "home",
      element: <Home />,
    },
    {
      path: "portfolio",
      element: <Portfolio />,
      //loader: appLoader,
    }, {
      path: "pricing",
      element: <Pricing />
    },
    {
      path: "products",
      element: <Products cnx={cnx} />
    },
    {
      path: "cart",
      element: <ShoppingCart cnx={cnx} />
    },
    {
      path: "checkout",
      element: <CheckOut />
    },

    {
      path: "login",
      element: <Login />
    },
    {
      path: "signup",
      element: <Signup />
    },
    {
      path: "login",
      element: <LoginOut />
    },
    {
      path: "/oauth/signin-cb",
      element: <OAuthCallback/>

    },
    {
      path: "unauthorized",
      element: <UnAuthorized />
    },
    {
      path: "forgetPassword",
      element: <ForgotPassword />
    },
    {
      path: "requireadmin",
      element: <RequireAdmin />
    },
    {
      path: "thankyou",
      element: <Thanks />
    },
    {
      path: "test",
      element: <Test />
    },
    {
      path: "notfound",
      element: <NotFound />
    },
    {
      path: "error",
      element: <ErrorPage />
    }

  ],
};

  if (cnx.siteState.viewsData && cnx.siteState.viewsData.length) {

    let dynamicRoutes: RouteObject[]=[];

    for (let viewData of cnx.siteState.viewsData) {

      const _paramId = ':' + viewData.paramId;

      dynamicRoutes.push({
        path: viewData.routeName,
        element: <DefaultModelLayout viewData={viewData} />,
        errorElement: <RootBoundary />,
        children: [
          {
            
            index: true,
            element: <RenderList />,
            loader: authLoader('list', viewData)

          },
          {
            path: "list",
            element: <RenderList />,
            loader: authLoader('list', viewData)
          },
          {
            path: _paramId,
            element: <RenderModel  />,
            loader: authLoader('get', viewData)
          },
          {
            path: "create",
            element: <CreateModel />,
            loader: authLoader('create', viewData, true),
            action: defaultAction(viewData),
          },
          {
            path: _paramId + "/update",
            element: <EditModel/>,
            loader: authLoader('update', viewData, true),
            action: defaultAction(viewData)
          },
          {
            path: _paramId + "/patch",
            element: <EditModel />,
            loader: authLoader('patch', viewData,true),
            action: defaultAction(viewData),
          },
          {
            path: _paramId + "/delete",
            element: <DeleteModel  />,
            loader: authLoader('delete', viewData),
            action: defaultAction(viewData),
          }
        ]
      })
    }
    _routes.children = [..._routes.children!, ...dynamicRoutes];
  }

  let browserRoutes:any = createBrowserRouter([_routes]);
  let listOfRoutespath: string[] = [];

  if(browserRoutes && browserRoutes.routes.length){

    for (let r of browserRoutes.routes) {
     if (r.children){
       let m = r.children.map((e:any)=> e.path);
   
       listOfRoutespath.push(...m);
     }else {
      r.path && listOfRoutespath.push(r.path);
     }
     }
     }
    // store
    cnx.appCaches.set('routesPath',listOfRoutespath)
    


  return browserRoutes;
}, [cnx])

}
