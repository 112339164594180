
import { Outlet, useOutletContext } from 'react-router-dom';
import { IModelViewData, ISiteContextValues, IViewData } from '../../types/utils';
import { FeaturedPosts } from '../../Blog/blog';
import { MaroonCard} from '../../components';

import { Loading, RenderCategories} from '../../components/helpers';
import { useSiteContext } from '../../utils/context';
import { sanitizeHTML } from '../../utils/sanitize';
import { useMemo } from 'react';
import { ModelViewtData } from '../../utils/modelViewData';

export interface IModelLayoutContext {
  cnx: ISiteContextValues
  modelViewData: IModelViewData
}
export function useViewDataInstance(props:{viewData: IViewData}): IModelViewData{
  const {viewData }= props;
  return useMemo(()=>{ 
    let div = "<div>";
    let modelTemplate = viewData.modelTemplate || div;
    let listTemplate = viewData.listTemplate || div;
    let _modelKeys = [...viewData.modelKeys, 'createdAt', 'updatedAT', '_id'];
    
    function toTemplate(keys:string[], template:string){
      for (let _key of keys) {
        template +=  "<p>"+ _key  + " :  ${" +  _key + "}</p>";
      }
      template += "</div>";

    return template;
    }
    if (listTemplate ===  div) {
      let _list =  _modelKeys && (_modelKeys.length > 3) ? [_modelKeys[0], _modelKeys[1],_modelKeys[0],'createdAt'] : _modelKeys
      listTemplate = toTemplate(_list, listTemplate);
      listTemplate = sanitizeHTML(listTemplate, false) as string;

    } else if(modelTemplate === div) {

      modelTemplate = toTemplate(viewData.modelKeys,modelTemplate)
      modelTemplate = sanitizeHTML(modelTemplate, false) as string;

    }

 let _viewData = new ModelViewtData({...viewData,modelTemplate, listTemplate});
 return _viewData;
  
},[viewData])
}

export const useModelLayoutContext = () => useOutletContext<IModelLayoutContext>();

export function DefaultModelLayout(props: { viewData: IViewData}) {

  const cnx = useSiteContext()!;
  const sidebar = props.viewData.name  === 'post';
  
 const modelViewData = useViewDataInstance({viewData:props.viewData});

  return <MaroonCard title={props.viewData.displayName}>
    <div className="container-fluid p-0 ">
      {sidebar && <FeaturedPosts />}

      <div className="row">
        <div className={sidebar ? "col-md-9" : "col-md-12"}>

         { modelViewData ? <Outlet context={{ cnx, modelViewData} as IModelLayoutContext} />: <Loading/> }

        </div>
        {sidebar &&
          <div className="col-md-3">
            <div className="position-sticky" style={{ top: 10 }}>
              <div className="p-4 mb-3 bg-light rounded">
                <h4 className="fst-italic">About</h4>
                <p className="mb-0">Customize this section to tell your visitors a little bit about your publication, writers, content, or something else entirely. Totally up to you.</p>
              </div>
              <RenderCategories />
              <div className="p-4">
                <h4 className="fst-italic">Archives</h4>
                <ol className="list-unstyled mb-0">
                  <li><a href="/">March 2021</a></li>
                  <li><a href="/">February 2021</a></li>
                  <li><a href="/">January 2021</a></li>
                  <li><a href="/">December 2020</a></li>
                  <li><a href="/">November 2020</a></li>
                  <li><a href="/">October 2020</a></li>
                  <li><a href="/">September 2020</a></li>
                </ol>
              </div>

              <div className="p-4">
                <h4 className="fst-italic">Elsewhere</h4>
                <ol className="list-unstyled">
                  <li><a href="/">GitHub</a></li>
                  <li><a href="/">Twitter</a></li>
                  <li><a href="/">Facebook</a></li>
                </ol>
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </MaroonCard>
};