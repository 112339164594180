import {useState} from 'react';
import Banner from '../components/banner';

import  auto1 from '../assets/images/automation1.jpeg';
import  auto4 from '../assets/images/automation4.jpg';
//import {SiteContext } from '../utils/context';
const FeaturedCol =(props:{title:string,paragraph:string,iconTop:string,iconBottom:string,linkText:string,link?:"/",})=>{
  return (
    <div className="feature col">
          <div className="feature-icon bg-primary bg-gradient">
            <i className={props.iconTop}></i>
          </div>
          <h2>{props.title}</h2>
          <p>{props.paragraph}</p>
          <a href={props.link} className="icon-link">
            {props.linkText}
            <i className={props.iconBottom}></i>
          </a>
        </div>
        )
}
 function Home(){

    return (
    <div  className='container-fluid border-top px-0 fadein' id="featured-3">
  <Banner/>

  <AlertDismissibleExample />
  <div className='row'>
    <div className='col-lg-6 col-md-6 col-sm-6'>
    <div className="card ">
  <img src={auto4} height="200" className="card-img-top" alt="..."/>
  <div className="card-body">
    <h5 className="card-title">IT Automation</h5>
    <p className="card-text">We deliver set of practices that combines software development and IT operations. It aims to shorten the systems development life cycle and provide continuous delivery. To helps to increase organization speed to deliver applications and services. </p>
    <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
  </div>
</div>
    </div>
    <div className='col-lg-6 col-md-6 col-sm-6'>
    <div className="card">
  <img src={auto1} height="200" className="card-img-top" alt="..."/>
  <div className="card-body">
    <h5 className="card-title">Processes Automation</h5>
    <p className="card-text">We deliver set of practices that combines software development and IT operations. It aims to shorten the systems development life cycle and provide continuous delivery. To helps to increase organization speed to deliver applications and services.</p>
    <p className="card-text"><small className="text-muted">Last updated 3 mins ago</small></p>
  </div>
</div>
    </div>
  </div>
  {/*
<hr/>
      <h2 className="pt-3">Columns with icons</h2>
      <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
        <FeaturedCol iconTop='bi bi-newspaper' title='Featured title' paragraph="Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words." link='/' linkText='Call to action' iconBottom='bi bi-chevron-double-right'/>
        <FeaturedCol iconTop='bi bi-broadcast' title='Featured title' paragraph="Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words." link='/' linkText='Call to action' iconBottom='bi bi-chevron-double-left'/>
        <FeaturedCol iconTop='bi bi-toggles2' title='Featured title' paragraph="Paragraph of text beneath the heading to explain the heading. We'll add onto it with another sentence and probably just keep going until we run out of words." link='/' linkText='Call to action' iconBottom='bi bi-chevron-double-right'/>

      </div>
    */}
    </div>
  
    );
}

function AlertDismissibleExample() {
  const [show, setShow] = useState(true);

  if (show) {
    return (
      <div className="alert alert-warning alert-dismissible fade show" role="alert" onClick={() => setShow(false)} >
        <h3 className="display-6">Hello dear visitor!</h3>
    <p className="lead">
        You are more than welcome, this is just a anotice that we are on the process of migrating our site to this one and that require some time <br/>
        once again <span title='Thanks Mahady! for the correction'>thanks</span>  for your interest in our site, you can drop us a line if you have query.
      </p>
      </div>
    );
  }
  return <button className='btn btn-warning' onClick={() => setShow(true)}>Show Alert</button>;
}

export default  Home