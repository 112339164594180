import { IModelForm } from '../../types/utils';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useModelLayoutContext } from './default_model_layout';
import { useFarmBuilder } from '../../utils/forms';
import { Loading } from '../../components';

export default function EditModel() {

  const { cnx, modelViewData} = useModelLayoutContext()!
  const modelForm: IModelForm = useLoaderData() as any;
  const { ajax } = cnx;
const {paramId, routeName, displayName} = modelViewData.viewData
  const location = useLocation();
  const navigate = useNavigate();

  const paramValue = useParams()[paramId]


  const { AppForm, farmState, setFarmState } = useFarmBuilder({ modelForm, method: 'put', sendUrl: routeName + '/' + paramValue + '/update', send: true});

  useEffect(() => {
    // reset formData
    let user = cnx.auth.user?.profile;
    if(user && !cnx.isAdmin)
       navigate('/unauthorized');
  }, [cnx.auth.user, cnx.isAdmin, navigate]);

  // check if post in location and set state
  useEffect(() => {
    //console.log('edit data: ',farmState.data)
    if (!farmState.data && location.state && location.state.model) {
      setFarmState({ ...farmState, data: location.state.model })
    } else if (paramValue) {

      ajax.get(routeName + '/' + paramValue, undefined, modelViewData.inUserAuth('get')).then((res) => {
        let { error, data } = res;
        if (data) {

          setFarmState({ ...farmState, data });
        } else if (error) {
         
          if(error === "unAuthorized!"){
           navigate('unauthorized')
          }else{
           navigate('error')
          }
          
        }
      })
    }
  }, [location.state, paramValue, routeName])

  return farmState.data ? (

    <div className=" container-fluid p-0 justify-content-md-center fadein">
      <div className='row'>
        <div className="col-md-10 col-sm-12 mx-auto ">
          <h4 className='text-center'>{'Update ' + displayName}</h4>
          <hr className='bg-warning' />
          <AppForm />
        </div>
      </div>
    </div>
  ) : <Loading />;

}
