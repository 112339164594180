import { createElement, useEffect, PropsWithChildren, useRef, useMemo} from 'react';
import Editor from 'react-simple-wysiwyg';

import history from '../utils/history';
import { checkedUp, validateChanges, useProcessFarm } from './html5validation'
import { LoadingButton } from '../components'
import { useNavigate } from 'react-router-dom';
import { IFormState, IFarmBuilder, FarmBuilderProps } from '../types/Iformland';
import { Ajax } from './ajax';

export const FormStateDefault = (partialData: Partial<IFormState>): IFormState => {
    const _defultData = {
        data: null,
        validated: false,
        errors: null,
        submitting: false,
        submitted: false,
        send: false,
        successData: null,
        onSuccessNavigateTo: null,
        thankYou: false
    }
    return { ..._defultData, ...partialData }
}
export function checkboxRadio(cls: string) {
    return "checkbox radio".indexOf(cls) !== -1
}

export function Label(props: { title: string, htmlFor: string, isRadioCheckBox?: boolean, formLable?: boolean }) {
    const { title, htmlFor, isRadioCheckBox, formLable } = props;
    let _class = "form-label";
    if (isRadioCheckBox)
        _class = "form-check-label";
    if (formLable)
        _class = ""

    return <label title={title} className={_class} htmlFor={htmlFor}> {title}:</label>
}

export function FormVarient(props: PropsWithChildren<{ isInputGroup?: boolean, isRadioCheckBox?: boolean, label?: { title: string, htmlFor: string }, icon?: string }>) {
    const { isInputGroup, isRadioCheckBox, label, icon, children } = props;

    let _class = "";
    if (isRadioCheckBox)
        _class = "form-check"
    else if (isInputGroup) {
        _class = icon ? "input-group" : "form-group"
    } else {
        _class = "form-floating"
    };

    return <div className={_class + " mb-3"} >
        {label && !isRadioCheckBox && isInputGroup && <Label isRadioCheckBox={false} {...label!} />}
        {children}
        {label && (isRadioCheckBox || !isInputGroup) && <Label formLable={_class === "form-floating"} isRadioCheckBox={isRadioCheckBox} {...label!} />}
        {isInputGroup && icon && <span className="input-group-text"><i className={icon}></i> </span>}
    </div>
}

export function useFarmBuilder(props: FarmBuilderProps): IFarmBuilder {

    const { isInputGroup, method, send, onSuccessNavigateTo, modelForm, thankYou, sendUrl, Extra } = props;

    const defaultData = FormStateDefault({ data: modelForm.initialState, send, onSuccessNavigateTo, thankYou, sendUrl });
    const navigate = useNavigate()

    const { farmState, setFarmState, handleSubmit, useFormChanges } = useProcessFarm(defaultData);
    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {

        if (farmState.submitting) {
            if (!farmState.submitted && farmState.validated && farmState.sendUrl && farmState.send) {

                // when every thing is clear send
                const ajax: any = new Ajax();
                ajax[method ?? 'post'](farmState.sendUrl, farmState.data).then((res: any) => {

                    if (res.success) {

                        setFarmState({ successData: res, errors: null, submitting: false, submitted: true });

                        //console.log('success result from useFormfeeds :', res, farmState)
                        // reset the form now
                        //formRef.current && formRef.current.reset();
                        if (farmState.thankYou) {
                            setFarmState()
                            navigate('/thankyou')


                        } else if (farmState.onSuccessNavigateTo) {
                            setFarmState()
                            navigate(farmState.onSuccessNavigateTo)
                        }

                    } else {
                        setFarmState({ successData: null, errors: res.error, submitting: false, submitted: false });
                    }

                }).catch((errors: any) => {
                    setFarmState({ successData: null, errors, submitting: false, submitted: false });
                });

            }
        }
    }, [farmState.submitting, farmState.send])


    const AppForm = (): JSX.Element => {
        const [inputState, setInputChanges] = useFormChanges(farmState.data);

    
            const _childeren = modelForm && Object.keys(modelForm.elements).map((_key, i) => {
                //console.log(_key, inputState[_key])
                let elmProps: any = modelForm.elements[_key];
    
                let tagname = elmProps.tag;
    
                let label = {
                    title: elmProps['title'] || _key,
                    htmlFor: _key
                }
                let options = undefined;
                let elm: any;
                let isCheckRadio = elmProps.type ? checkboxRadio(elmProps.type) : false
                //set onChange
                elmProps.onChange = setInputChanges;
    
    
                if (tagname === 'select') {
                    options = elmProps.options.map((e: any, i: number) => <option key={i}  {...e}>{e.title}</option>)
    
                    elm = createElement(tagname,  { ...elmProps }, options);
                } else if (tagname === 'textarea') {
    
                    elm = !elmProps.html &&  createElement(tagname, { ...elmProps,value:inputState[_key], id: _key });
    
                    if (elmProps.html) {
    
                         let fake_event =(value:string)=> {

                           return  {
                             target: {
                                 type: 'html', name: _key,
                                 value
                             },
                             preventDefault: () => true
                         }
                         }
                         
                        return <Editor key={i} value={inputState[_key]} onChange={(e:any)=>setInputChanges(fake_event(e.target.value))} />
                        
                    }
                } else {
    
                    if (isCheckRadio) {
                        elmProps['checked'] = inputState[_key]
                    } else {
                        elmProps.value = inputState[_key];
    
                    }
    
                    elm = createElement(tagname, { ...elmProps });
                }
    
                return <FormVarient key={i} isInputGroup={isInputGroup} isRadioCheckBox={isCheckRadio} icon={elmProps.icon} children={elm} label={label} />
            });
          
        return  <form method={method} ref={formRef} id={modelForm.name} className={(farmState.validated ? 'was-validated' : 'needs-validation ') + ' my-3 py-3 '} onSubmit={handleSubmit!} noValidate>
            {farmState.errors && <div className='form-group py-3'><span className='text-center text-danger'> < strong> Errors :</strong> <br />{farmState.errors.message || String(farmState.errors)} </span></div>}
            {_childeren}

            <LoadingButton text='Cancel' loading={farmState.submitting} className='btn btn-warning my-3' onClick={() => history.back()} />
            <LoadingButton type='submit' loading={farmState.submitting} />
            {Extra && Extra}
        </form> 

    }


    return {
        AppForm,
        farmState,
        setFarmState,
    }
}


export { checkedUp, validateChanges }
