
import moment from 'moment';
import { Link, Outlet} from 'react-router-dom';
import '../css/blog.css';
import { IPost } from '../types/models';
import { ContinueReading, Loading, MaroonCard} from '../components';
import { useSiteContext} from "../utils/context";
import { useAppFetcher } from "../utils/ajax";
import { RenderCategories } from '../components/helpers';
//import { useSiteState } from '../utils/useSiteState';

export default function Blog() {
  //const cnx = useSiteContext()!;  

  // for js highlight programming code in posts
 //const jsState = useLoadScript(["https://cdn.jsdelivr.net/gh/highlightjs/cdn-release@11.6.0/build/highlight.min.js"]);
 //const cssState = useLoadScript(["https://cdn.jsdelivr.net/gh/highlightjs/cdn-release@11.6.0/build/styles/default.min.css"]);




 // const Category = (cat: ICategory) => <Link to={'/posts/list?page=1&category=' + cat._id} state={{ category: cat }} className="btn btn-outline-dark btn-sm m-1">{cat.name}</Link>;

  return (
    <div className="container-fluid p-0 fadein">
      <FeaturedPosts/>

      <div className="row">
        <div className="col-md-9">

        <Outlet /> 

        </div>
        <div className="col-md-3">
          <div className="position-sticky" style={{ top: 10 }}>
            <div className="p-4 mb-3 bg-light rounded">
              <h4 className="fst-italic">About</h4>
              <p className="mb-0">Customize this section to tell your visitors a little bit about your publication, writers, content, or something else entirely. Totally up to you.</p>
            </div>

            
   
      <RenderCategories/>

            <div className="p-4">
              <h4 className="fst-italic">Archives</h4>
              <ol className="list-unstyled mb-0">
                <li><a href="/">March 2021</a></li>
                <li><a href="/">February 2021</a></li>
                <li><a href="/">January 2021</a></li>
                <li><a href="/">December 2020</a></li>
                <li><a href="/">November 2020</a></li>
                <li><a href="/">October 2020</a></li>
                <li><a href="/">September 2020</a></li>
                <li><a href="/">August 2020</a></li>
                <li><a href="/">July 2020</a></li>
                <li><a href="/">June 2020</a></li>
                <li><a href="/">May 2020</a></li>
                <li><a href="/">April 2020</a></li>
              </ol>
            </div>

            <div className="p-4">
              <h4 className="fst-italic">Elsewhere</h4>
              <ol className="list-unstyled">
                <li><a href="/">GitHub</a></li>
                <li><a href="/">Twitter</a></li>
                <li><a href="/">Facebook</a></li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export function FeaturedPosts() {

  const state = useAppFetcher<IPost[]>({method:'GET',path:'posts',querys:{ 'isfeatured': true, limit:2, 'page': 1 }});

  const RenderFeatured = (model: IPost) => (
    <div className="col-md-6 col-sm-6" >
      <MaroonCard textSize="sm" title='Featured'>

        <h5 className="text-truncate">{model.title}</h5>
        <h6 className="mb-0 fw-bold">Featured post</h6>
        <div className="mb-1 text-muted">
          {moment(model.createdAt).format('MMMM Do, YYYY | h:mm a')}
          | By:  <Link to={"/portfolio"}>
            {model.author?.firstname! + ' ' + model.author?.lastname!}
          </Link>
        </div>
        <p className="card-text text-truncate">{model.descriptions}.</p>
        <ContinueReading to={'/posts/' + model._id} state={{ model }} />

      </MaroonCard>
    </div>
  )

  return (
    state.data && state.data.length ?
      (<div className="row">
        {state.data.map((p: IPost) => <RenderFeatured {...p} key={p._id} />)}
      </div>) : <Loading />
  );
}

