import { useRouteError } from "react-router-dom";
import { GoHome } from "./gohome";

export default function ErrorPage() {
  const error:any = useRouteError();
  console.error(error);

  return (
    <div className="text-center mx-auto" id="error-page">
      <h1 className="text-danger text-center">Oops!</h1>
      <p className="lead">Sorry, an unexpected error has occurred.</p>
      <p>
        <i>{error ? ( error.statusText || error.message) : ""}</i>
      </p>
      <GoHome/>
    </div>
  );
}