
import { NavLink} from "react-router-dom";
import { useSiteContext } from '../utils/context';
import { isIn, Loading } from "./helpers";

import { LoginOut } from "../pages/Login";

export default function Menu() {

  const cnx = useSiteContext()!;

  const {viewsData} = cnx.siteState;

 const addActive = (active: { isActive: boolean }) => (active.isActive === true ? "nav-link active" : "nav-link");

//===============================================


  const adminN =  viewsData && viewsData.map((vData, key) => {
      const {routeName,adminAuth, displayName, name } = vData;

      let listReqAdmin = isIn(adminAuth,'list');
      let createReqAdmin = isIn(adminAuth,'create');
      let active = addActive;

      if (cnx.isAdmin) {
       return <DropdownNav key={key} {...{name,routeName,displayName,active}} />
      }else if (!listReqAdmin && !createReqAdmin){
        return <DropdownNav key={key} {...{ name,routeName,displayName,active}} />
      }else if (!listReqAdmin){
        return <NavItem key={key} {...{ name,displayName,active}} to={routeName+'/list'} />
      }else if (!createReqAdmin){
        return <NavItem key={key} displayName={'Create '+name} to={routeName + '/create'} active={active}/>
      }else{
        return <span key={viewsData.length+1}></span>;
      }
    }) 

  const AdminNav  = ()=> <>{adminN}</>


//==================  
return (
    <nav id="mainNav" className="navbar navbar-expand-lg bg-body-tertiary sticky-top  " aria-label="Light offcanvas navbar">
      <div className="container-xl">
        <BrandName />
        <button className="navbar-toggler collapsed" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbarLight" aria-controls="offcanvasNavbarLight">
          <span className="navbar-toggler-icon"></span> {""}
        </button>

        <div className="offcanvas offcanvas-end " tabIndex={-1} id="offcanvasNavbarLight" aria-labelledby="offcanvasNavbarLightLabel">
          <div className="offcanvas-header">

            <h5 className="offcanvas-title" id="offcanvasNavbarLightLabel">
              <BrandName />
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>

          </div>
          <div className="offcanvas-body">
            <ul className="navbar-nav justify-content-end flex-grow-1 ">

              <NavItem to="/" displayName="Home" active={addActive}  />
              <NavItem to="portfolio" displayName="Portfolio" active={addActive}  />
              
             { cnx.siteState.isReady && viewsData?.length ? <AdminNav /> : <Loading/>}
            </ul>

            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3">

              <li className="nav-item" data-bs-dismiss="offcanvas">
                <NavLink className={addActive} to="/cart"><i className="bi bi-cart-plus"></i></NavLink >
              </li>
              <li className="nav-item" data-bs-dismiss="offcanvas">
              <LoginOut/> 
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

 const BrandName = () =>
  <NavLink className="navbar-brand border-top  border-3 border-danger px-2 rounded-3 shadow" to="/" role="button">
    <span className="h4 ">Khalil <i className="bi bi-person-workspace fs-3 text-success"></i> Tuban</span>
  </NavLink>

const NavItem =(props:{to:string,displayName:string, active:any})=><li  className="nav-item" data-bs-dismiss="offcanvas">
<NavLink className={props.active} to={props.to} end={true}>{props.displayName}</NavLink >
</li>

const DropdownNav =(props:{ routeName:string,displayName:string, active:any , name:string})=> { 
  const {routeName,displayName, active, name} =props;
  return <li  className="nav-item dropdown" >
<NavLink className="nav-link dropdown-toggle " to={routeName} end={true} role="button" data-bs-toggle="dropdown" aria-expanded="false">
  {displayName}
</NavLink>
<ul className="dropdown-menu" >
  <li data-bs-dismiss="offcanvas">
    <NavLink className="dropdown-item" to={routeName + "/list"} end={true}>{displayName}
    </NavLink>
  </li>
  <li data-bs-dismiss="offcanvas" >
    <NavLink className="dropdown-item" to={routeName + "/create"} >  {'Create ' + name}
    </NavLink >
  </li>
</ul>
</li>

}




