
//import history from '../utils/history';
import { Link } from 'react-router-dom';
import MaroonCard from '../components/MaroonCard';
import { useFarmBuilder} from '../utils/forms';
//import { useSiteContext } from '../utils/context';
import { IModelForm } from '../types/utils';

//let  gg = <button children ></button>
export default function Signup () {
 // const context = useSiteContext()!;

  const modelForm: IModelForm = {
    name:"Signup",
    elements:{
      email:{ tag:'input', name:'email',required:true,className:"form-control",type:"email", minLength: 3, maxLength: 20, title:"Your email"},
      password:{ tag:'input', name:'password', type:"password",className:"form-control", minLength: 6, maxLength: 11,required:true,title:"your password"},
      confirmpassword:{ tag:'input', name:'confirmpassword',className:"form-control", type:"password",required:true, title:"Confirm password"},
      terms:{ tag:'input', name:'terms',className:"form-check-input", type:"checkbox",required:true,title:"agree to terms!" },
    },
  initialState:{email:"",password:"",confirmpassword:"",terms:false}
  }

 const {AppForm}= useFarmBuilder({sendUrl:"accounts/signup",send:true,modelForm});
  return (

<div className="row fadein my-5">
      <div className="col-md-6 mx-auto">
        <MaroonCard title='New Account'>
        <h4 className='text-center my-3'>Create New Account</h4>

         <AppForm/>

              <div className="d-flex justify-content-center align-items-center text-center mt-4">
                  <p className="form-text"> Already have an account? <Link to='/login' className="nav-link h4"> Login </Link></p> 
                    
                 
                </div>
        </MaroonCard>
      </div>
    </div>
    );
};